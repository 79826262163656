import { faFile, faFileExcel, faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AssetPreview = ({ type ,src }) => {
    const checkImage = type?.split("/")[0]
    if (checkImage === 'image') {
      return (<img
        src={src}
        alt="thumbnail"
        className="thumbnail"
      />)
    } else {
      switch (type?.toLowerCase().trim()) {
        case 'application/pdf':
          return (<FontAwesomeIcon icon={faFilePdf} size="2x"  />)
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return (<FontAwesomeIcon icon={faFileWord} size="2x" />)
        case 'application/msword':
          return (<FontAwesomeIcon icon={faFileWord} size="2x" />)
        case 'application/vnd.ms-excel':
          return (<FontAwesomeIcon icon={faFileExcel} size="2x" />)
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return (<FontAwesomeIcon icon={faFileExcel} size="2x" />)
        default:
          return (<FontAwesomeIcon icon={faFile} size="2x" />)
      }
    }

  }

  export default  AssetPreview