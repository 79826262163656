import React, { useEffect, useMemo, useState } from 'react';
import './style.css';
import {COUNTRIES_LIST} from '../../Config/Data'

const CountrySelector = ({ value, name, handleChange, inputClass }) => {
    const [show, setShow] = useState(false)
    const [optionList, setOptionList] = useState(false)


    useEffect(() => {
        if (value === '') {
            const list = COUNTRIES_LIST.map(item => item.value)
            const mySet = new Set([...list]);
            setOptionList(Array.from(mySet))
        } else {
            const filter = COUNTRIES_LIST.filter(f => {
                if (f.value.toLowerCase().includes(value.toLowerCase())) {
                    return true
                }
                else if (f?.othername?.toLowerCase().includes(value.toLowerCase())) {
                    return true
                } else {
                    return false
                }
            })
            const values = filter.map(item => item.value)
            const mySet = new Set([...values]);
            setOptionList( Array.from(mySet))
        }

    }, [value])
    return (
        <div className='mb-3'>
            <p className={`mainLabel ${inputClass ? inputClass : 'p-0 m-0'}`}>{name}</p>
            <input
                type={"text"}
                id={name}
                name={name}
                className={`mainInput `}
                onChange={(e) => {
                    setShow(true)
                    handleChange(e.target.value)
                }}
                autoComplete={`new-${name}`}
                value={value}
                onBlur={() => {
                    setTimeout(() => {
                        setShow(false)
                    }, 500)
                }}
            />
            <div className='w-100' style={{position:'relative'}}>
            <div className='country-dropdown'>
                <div className='country-dropdown-list'>
                    {(show && optionList?.length > 0) && optionList.map((item, index) => (
                        <div className='my-1 px-2  py-1' key={index} value={item} onClick={() => {
                            setShow(false)
                            handleChange(item)
                        }}>
                            {item}
                        </div>
                    ))}
                </div>
            </div>
            </div>

        </div>
    );
}

export default CountrySelector;