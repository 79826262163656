import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomLoader from "../../Components/CustomLoader";

import "./style.css";
import BASEURL from "../../Config/global";

const AddSubscription = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [sliderList, setSliderList] = useState([]);


  useEffect(() => {
    getSlierByApi();
  }, []);

  const getSlierByApi = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/infoboxes`
      );
      console.log(response.data.data)
      setSliderList(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const formDataMethod = new FormData();
      formDataMethod.append("title", formData.title);
      formDataMethod.append("price", formData.price);
      formDataMethod.append("interval", formData.interval);
      formDataMethod.append("description", formData.description);
      formDataMethod.append("primary_package_user", formData?.primary_package_user?.id);
      formDataMethod.append("household_package_user", formData.household_package_user?.id);
      setLoader(true);
      const response = await axios.post(`${BASEURL}/api/user/packages`, formDataMethod);
      // Handle the response data here, e.g., log it or use it in your application.
      if (!response.data.error) {
        successModal.fire({
          text: "Subscription Added Successfully",
          confirmButtonText: "Continue",
        });
        setLoader(false);
        navigate("/subscription-management");
      } else {
        setLoader(false);
      }
      setLoader(false);
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
      setLoader(false);

      // You can also provide specific error messages or actions based on the error type:
      if (error.response) {
        // The request was made, but the server responded with an error.
        console.error("Server error:", error.response.data);
        setLoader(false);
      } else if (error.request) {
        // The request was made, but there was no response from the server.
        console.error("No response received from the server.");
        setLoader(false);
      } else {
        // Something else went wrong.
        console.error("An unexpected error occurred:", error.message);
        setLoader(false);
      }
    }

  }

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Add Subscription
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">

                  <div className="col-lg-4 mb-2">
                    <CustomInput
                      label="Title"
                      labelClass="mainLabel"
                      required
                      type="text"
                      name="title"
                      value={formData.title || ""}
                      placeholder="Enter Title"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-4 mb-2">
                    <CustomInput
                      label="Price"
                      labelClass="mainLabel"
                      required
                      type="number"
                      name="price"
                      value={formData.price || ""}
                      placeholder="Enter Price"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-4 mb-2">
                    <p className="mainLabel mb-4">Subscription Type*</p>
                    <label className="secondaryText me-3">
                      <input
                        type="radio"
                        name="interval"
                        required
                        value={"month"}
                        className="me-1"
                        onChange={handleChange}
                      />
                      Monthly
                    </label>
                    <label className="secondaryText">
                      <input
                        type="radio"
                        name="interval"
                        required
                        value={"year"}
                        className="me-1"
                        onChange={handleChange}
                      />
                      Yearly
                    </label>
                  </div>

                  <div className="col-lg-12 mb-2">
                    <div className="inputWrapper">
                      <label className="mainLabel">Description*</label>
                      <textarea
                        rows="5"
                        placeholder="Enter Description"
                        required=""
                        name="description"
                        className="mainInput"
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea></div>
                  </div>

                  <div className="col-lg-4 mb-2">
                    <p className="mainLabel">Primary User</p>
                    {sliderList && (
                      <select
                        name="primary_package_user"
                        id="primary_package_user"
                        className="mainInput w-100"
                        value={formData?.primary_package_user?.id || ""}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFormData({
                            ...formData, [name]: {
                              ...formData[name],
                              "id": Number(value)
                            }
                          });
                        }}
                      >
                        <option value={null}> Select </option>
                        {sliderList.length > 0 && sliderList.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.slider_name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="col-lg-4 mb-2">
                    <p className="mainLabel">Household User</p>
                    {sliderList && (
                      <select
                        name="household_package_user"
                        id="household_package_user"
                        className="mainInput w-100"
                        value={formData?.household_package_user?.id || ""}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFormData({
                            ...formData, [name]: {
                              ...formData[name],
                              "id": Number(value)
                            }
                          });
                        }}
                      >
                        <option value={null}> Select </option>
                        {sliderList.length > 0 && sliderList.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.slider_name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  
                </div>

                <div className="row mt-5">
                  <div className="col-12">
                    <CustomButton
                      type="submit"
                      variant="primaryButton"
                      text="Add"
                    />
                  </div>
                </div>
              </form>
            </div>





          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default AddSubscription;
