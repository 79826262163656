import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import PromoView from "../../Components/Displays/Views/PromoView";
import BASEURL from "../../Config/global";


const CouponDetail = () => {

  const { id } = useParams();
  const [formConfig, setformConfig] = useState({
    "main_config": {
      "id": null,
      "title": "Detail Promo",
      "form_type": "View",
    },
    "initial_data": {},
    "api_config": {
      "url": `${BASEURL}/api/user/coupon`,
      "method": "POST",
    },
    "slider_api_config": {
      "url": `${BASEURL}/api/user/infoboxes`,
      "method": "GET",
    },
    "button_config": {
      "title": "View Promo",
    }
  })

  useEffect(() => {
    if (id && id != null && id != undefined) {
      setformConfig({
        ...formConfig,
        "main_config": {
          "id": id,
          "title": "Edit Promo",
          "form_type": "Edit",
        }
      })
    }
  }, [id]);

  return (
    <>
      <DashboardLayout>
        <PromoView config={formConfig} />
      </DashboardLayout>
    </>
  );
};
export default CouponDetail;
