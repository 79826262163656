import { useState } from "react";
import { useNavigate } from "react-router";

import axios from "axios";

import { questionModal, successModal } from "../../Components/CustomModal";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomButton from "../../Components/CustomButton";

import "./styles.css";
import BASEURL from "../../Config/global";
import {  faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uploadImagePlaceholder } from "../../Assets/images";
import AssetPreview from "../../Components/AssetPreview";

const AddUpload = () => {
  const navigate = useNavigate();

  const [inputValue, setinputValue] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageType, setSelectedImageType] = useState(null);

  const [soundImage, setSoundImage] = useState(null);

  const handleChange = (event) => {
    setinputValue(event.target.value);
  };


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return
    }
    setSelectedImage(file);

    setSelectedImageType(file?.type ? file.type : null)
    if (file === null || file === "undefined") {
      setSoundImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSoundImage(reader.result);
      };
    }
  };


  const postData = async () => {

    const formdata = new FormData();
    formdata.append("asset", selectedImage);
    formdata.append("title", inputValue);
    formdata.append("type", selectedImageType);
    try {
      const response = await axios.post(
        `${BASEURL}/api/misc/asset/`,
        formdata,
      );
      return response
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event) => {
    if (inputValue === "" || !selectedImage) {
      return;
    }
    event.preventDefault();
    if (inputValue) {
      questionModal
        .fire({
          title: "Do you want to add the Asset?",
          confirmButtonText: "Add",
        })
        .then((result) => {
          if (result.isConfirmed) {
            postData().then(() => {
              successModal.fire({
                text: 'Asset Added Successfully',
                confirmButtonText: "Continue",
              });
              navigate("/upload-management");

            });

          }
        });
    }
  };
  const handleRemoveImage = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSoundImage(null)
    setSelectedImageType(null)
    setSelectedImage(null)
  }

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Add Upload
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-lg-6 mb-2">
                  <div className="inputWrapper">
                    <label className="mainLabel">Title*</label>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="text"
                        placeholder="Enter Title"
                        required
                        name="Title"
                        className="mainInput"
                        value={inputValue.category}
                        onChange={handleChange}
                      />
                      
                    </div>
                  </div>
                </div>
                <AddImageComponent
                  fileRef="File name"
                  soundImage={soundImage}
                  handleImageChange={handleImageChange}
                  handleRemoveImage={handleRemoveImage}
                  selectedImageType={selectedImageType}
                />

              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <CustomButton
                    type="button"
                    variant="primaryButton"
                    text="Add"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default AddUpload;


const AddImageComponent = ({ handleImageChange, soundImage, handleRemoveImage, fileRef = "Image", selectedImageType }) => {
  const imageType = selectedImageType?.split("/")[0] === "image"
  return (
    <div className="col-lg-3 mb-2">
      <p className="mainLabel ">{fileRef}*</p>
      <label className='icon-wrapper'>
        <div className="icon-on-top-right" onClick={handleRemoveImage}>
          <FontAwesomeIcon className="cross-icon-style" icon={faTimesCircle} />
        </div>
        <div className="imageInput">
          {soundImage && imageType ? (
            <AssetPreview type={selectedImageType} src={soundImage} />
          ) : (!selectedImageType?<img src={uploadImagePlaceholder} alt="place"/>:
            <AssetPreview type={selectedImageType} src={uploadImagePlaceholder} />
          )}
        </div>
        <input
          type="file"
          id="image"
          className="d-none"
          required
          name="banner"
          onChange={handleImageChange}
        />
      </label>
    </div>
  )
}
