import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import { COUNTRIES_LIST } from '../../Config/Data'

const useClickOutside = (ref, callback) => {
  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);
};

const CountrySelector = ({ value, name, handleChange, inputClass }) => {
  const [show, setShow] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [current, setCurrent] = useState('');
  const ref = useRef(null);

  useClickOutside(ref, () => {
    setShow(false);
  });

  useEffect(() => {
    if (value !== "") {
      setSelectedCountry(value.split(","));
    } else {
      setSelectedCountry([]);
    }
  }, []);

  useEffect(() => {
    if (current === '') {
      const list = COUNTRIES_LIST.map(item => item.value);
      const mySet = new Set([...list]);
      setOptionList(Array.from(mySet));
    } else {
      const filter = COUNTRIES_LIST.filter(f => {
        if (f.value?.toLowerCase().includes(current?.toLowerCase())) {
          return true;
        } else if (f?.othername?.toLowerCase()?.includes(current?.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
      const values = filter.map(item => item.value);
      const mySet = new Set([...values]);
      setOptionList(Array.from(mySet));
    }
  }, [current]);

  return (
    <div className='mb-3' ref={ref}>
      <p className={`mainLabel ${inputClass ? inputClass : 'p-0 m-0'}`}>{name}</p>
      <input
        type={"text"}
        id={name}
        name={name}
        className={`mainInput `}
        onKeyDown={(event) => {
          if (event.key === 'Backspace') {
            event.preventDefault();
            const newCountryArray = [...selectedCountry.slice(0, -1)];
            setSelectedCountry(newCountryArray);
            handleChange(newCountryArray.join(','))
          }
        }}
        onFocus={() => {
          setShow(true);
          // setTimeout(()=>ref?.current?.focus(),100)
        }}
        onBlur={() => {
          // setShow(false);
        }}
        autoComplete={`new-${name}`}
        value={selectedCountry.join(',  ')}
        placeholder="Select Countries"
      />
      <div className='w-100' style={{ position: 'relative' }}>
        {
          show ?
            <div className='country-dropdown' >
              <div className='country-dropdown-list'>
                <input
                  type={"text"}
                  id={name}
                  name={name}
                  className={`mainInput-2`}
                  onChange={(e) => {
                    setShow(true);
                    setCurrent(e.target.value);
                    // handleChange(e.target.value)
                  }}
                  autoComplete={`new-${name}`}
                  placeholder='Type to filter results'
                  value={current}
                //   onBlur={() => {
                //     debugger
                //     setTimeout(() => {
                //       setShow(false);
                //       // ref?.current.f
                //     }, 500)
                //   }}
                />
                {optionList.map((item, index) => (
                  <div className='my-1 px-2  py-1 d-flex' key={index} value={item} onClick={() => {
                    setShow(false);
                    handleChange(item);
                    if (selectedCountry.includes(item)) {
                      const newCountryArray = [...selectedCountry.filter(f => f !== item)]
                      setSelectedCountry(newCountryArray);
                      handleChange(newCountryArray.join(','))
                    } else {
                      const newCountryArray = [...selectedCountry, item]
                      setSelectedCountry(newCountryArray);
                      handleChange(newCountryArray.join(','))
                    }
                    setCurrent('')
                  }}>
                    <input
                      type="checkbox"
                      className="checkbox mx-2"
                      checked={selectedCountry.includes(item)}
                      onChange={(e) => {
                        // setFormData({ ...formData, 'is_expiry_unlimited': e.target.checked });
                      }}
                    />
                    {item}
                  </div>
                ))}
              </div>
            </div>
            : null
        }
      </div>
    </div>
  );
}

export default CountrySelector;