import React, { useEffect, useState } from 'react'
import BackButton from '../../BackButton';
import moment from 'moment';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FE_BASE_URL } from '../../../Config/global';

const PromoView = ({ config }) => {

    const [formData, setFormData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [copiedModal, setCopiedModal] = useState(false);

    useEffect(() => {
        if (config.main_config.id != null) {
            getDataByApi(config.main_config.id);
        } else {
            console.log("Did not find id")
        }
    }, [config]);

    const getDataByApi = async (id) => {
        try {
            const response = await axios.get(config.api_config.url + '/' + id);
            if (!response.data.error) {
                setFormData(response.data.data);
                console.log(response.data.data)
            }
        } catch (error) {
            console.error(error);
        }
    }


    const handleCopy = (url) => {
        navigator.clipboard.writeText(url);
        setShowModal(false);
        setCopiedModal(true);
        setTimeout(() => {
            setCopiedModal(false);
        }, 1500);
    }


    return (
        <div>
            {
                (formData.hasOwnProperty('title'))
                    ?
                    <div className="container-fluid">
                        <div className="row mb-3">
                            <div className="col-12 mb-2">
                                <h2 className="mainTitle">
                                    <BackButton />
                                    Promo Detail
                                </h2>
                            </div>
                        </div>

                        <div className="row mb-3">

                            <div className="col-12">
                                <br />
                            </div>

                            <div className="col-12">
                                <div className="px-4">
                                    <h2 className="mainTitle mb-4">{formData?.title}</h2>
                                    <p className="mb-1"><span className='lightColor'>Description: </span>{formData?.description}</p>
                                    <p className="mb-0"><span className='lightColor'>Promo Description: </span>{formData?.code_description}</p>
                                </div>
                            </div>

                            <div className="col-12">
                                <hr className="my-4" />
                            </div>

                            <div className="col-12">
                                <div className="row g-0 px-4">
                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Code</small>
                                        <p className="text-uppercase">{formData?.code}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Price</small>
                                        <p>{formData?.price}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Expires</small>
                                        <p>{(formData?.is_expiry_unlimited) ? 'Unlimited' : moment(formData?.expiry_date).format('MM-DD-YYYY')}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Created On</small>
                                        <p>{moment(formData?.created).format('MM-DD-YYYY')}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Updated at</small>
                                        <p>{moment(formData?.updated).format('MM-DD-YYYY')}</p>
                                    </div>

                                </div>
                            </div>

                            <div className="col-12"></div>

                            <div className="col-12">
                                <div className="row g-0 px-4">

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Subscription Type</small>
                                        <p className='text-capitalize'>{`${formData?.subscription_type}ly`}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">{formData?.duration_type === 'trial_period' ? 'Trial Period' : 'Term in days'}</small>
                                        <p>{formData?.duration_type === 'trial_period' ? formData?.trial_period : formData?.term_in_days}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Number of Uses Allowed</small>
                                        <p>{Number(formData?.number_of_uses_allowed)>0?formData?.number_of_uses_allowed:'Unlimited'}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Current Uses</small>
                                        <p>{formData?.current_uses}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Promo Status</small>
                                        <p>{formData?.active_status ? 'Active' : 'Inactive'}</p>
                                    </div>

                                </div>
                            </div>

                            <div className="col-12"></div>

                            <div className="col-12">
                                <div className="row g-0 px-4">


                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">M-tag</small>
                                        <p>{formData?.mtag}</p>
                                    </div>


                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Primary Info Slider</small>
                                        <p>{formData?.primary_promo_user?.name}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Household Info Slider</small>
                                        <p>{formData?.household_promo_user?.name}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <small className="lightColor mb-2">Propagation</small>
                                        <p>{formData?.is_propagation_allowed ? 'Enable' : 'Disable'}</p>
                                    </div>

                                    <div className="col-lg mb-2">
                                        <p className="mainLabel mb-2">Copy URL</p>
                                        <div className="copy-text">
                                            <button type='button' onClick={() => formData.code.length > 0 && setShowModal(true)}>
                                                <FontAwesomeIcon
                                                    icon={faClone}
                                                />
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='row g-0 px-4' >
                                    
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='row g-0 px-4' >
                                    <div className="col-lg-2 pl-4 mb-2 ">
                                        <small className="lightColor mb-2">Promo Asset</small>
                                        <p>{formData?.promo_asset?.title}</p>
                                    </div>
                                    <div className="col-lg-10 px-5 mb-2">
                                    <small className="lightColor mb-2">Countries</small>
                                    <p>{formData?.countries}</p>
                                    </div>
                                    {/* <div className="col-lg mb-2">
                                        
                                    </div>
                                    <div className="col-lg mb-2">
                                       
                                    </div>
                                    <div className="col-lg mb-2">
                                        
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-12"></div>

                            <br />
                            <br />

                            <div className="col-12"></div>

                            {/* Propagation Section */}

                            {
                                formData?.is_propagation_allowed ?

                                    <>
                                        <div className="col-12">
                                            <div className="px-4">
                                                <h2 className="mainTitle mb-4">{formData?.propated_coupon_details?.propagation_title}</h2>
                                                <p className="mb-1"><span className='lightColor'>Propagation Description: </span>{formData?.propated_coupon_details?.propagation_description}</p>
                                                <p className="mb-0"><span className='lightColor'>Propagation Promo Description: </span>{formData?.propated_coupon_details?.propagation_code_description}</p>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <hr className="my-4" />
                                        </div>

                                        <div className="col-12">
                                            <div className="row g-0 px-4">
                                                {/* <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">Code</small>
                                                    <p>{formData?.propated_coupon_details?.propagation_code}</p>
                                                </div> */}

                                                <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">Price</small>
                                                    <p>{formData?.propated_coupon_details?.propagation_price}</p>
                                                </div>

                                                <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">Expires</small>
                                                    <p>{(formData?.propated_coupon_details?.propagation_is_expiry_unlimited) ? 'Unlimited' : moment(formData?.propated_coupon_details?.propagation_expiry_date).format('MM-DD-YYYY')}</p>
                                                </div>

                                                <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">Created On</small>
                                                    <p>{moment(formData?.propated_coupon_details?.propagation_created).format('MM-DD-YYYY')}</p>
                                                </div>

                                                <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">Updated at</small>
                                                    <p>{moment(formData?.propated_coupon_details?.propagation_updated).format('MM-DD-YYYY')}</p>
                                                </div>
                                                <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">Subscription Type</small>
                                                    <p className='text-capitalize'>{formData?.propated_coupon_details?.propagation_subscription_type}ly</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12"></div>

                                        <div className="col-12">
                                            <div className="row g-0 px-4">

                                               

                                                <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">{(formData?.propated_coupon_details?.propagation_duration_type === 'trial_period') ? 'Trial Period' : 'Term in days'}</small>
                                                    <p>{formData?.propated_coupon_details?.propagation_duration_type === 'trial_period' ? formData?.propated_coupon_details?.propagation_trial_period : formData?.propated_coupon_details?.propagation_term_in_days}</p>
                                                </div>

                                                <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">Primary Info Slider</small>
                                                    <p>{formData?.propated_coupon_details?.propagation_primary_promo_user?.name}</p>
                                                </div>

                                                <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">Household Info Slider</small>
                                                    <p>{formData?.propated_coupon_details?.propagation_household_promo_user?.name}</p>
                                                </div>
                                                <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">Propagation</small>
                                                    <p>{formData?.propated_coupon_details?.propagation_is_propagation_allowed ? "Enable" : "Disable"}</p>
                                                </div>
                                                <div className="col-lg mb-2">
                                                    <small className="lightColor mb-2">Propagation Asset</small>
                                                    <p>{formData?.propated_coupon_details?.promo_propagation_asset?.title}</p>
                                                </div>
                                             
                                            </div>
                                        </div>

                                    </>

                                    :
                                    <></>
                            }



                        </div>
                    </div>
                    :
                    <div className="center-align">
                        <div className="spinner-border" role="status"></div>
                    </div>
            }


            <Modal
                show={showModal}
                centered
                className="swap-modal promo-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="pb-5 w-100 d-flex flex-column align-items-center">
                        <div className="text-end ms-auto">
                            <button
                                className="closeButton notButton ms-auto"
                                onClick={() => { setShowModal(false); }}
                            >
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className="cross-icon"
                                    color="white"
                                />
                            </button>
                        </div>
                        <div className="w-100 d-flex flex-column align-items-center" style={{ maxWidth: '550px' }}>
                            <div className="d-flex align-items-center mt-3 flex-column w-100">
                                <h2 className="mb-0 me-4">{"Copy Url"}</h2>
                                <br />
                                <br />
                                <div className="copy-text w-100">
                                    <input type="text" className="mainInput" value={`${FE_BASE_URL}/promo/${formData.code}`} disabled={true} />
                                    <button type='button' onClick={() => handleCopy(`${FE_BASE_URL}/promo/${formData.code}`)}>
                                        <FontAwesomeIcon
                                            icon={faClone}
                                        />
                                    </button>
                                </div>
                                <br />
                                <div className="copy-text w-100">
                                    <input type="text" className="mainInput" value={`${FE_BASE_URL}/promoint/${formData.code}`} disabled={true} />
                                    <button type='button' onClick={() => handleCopy(`${FE_BASE_URL}/promoint/${formData.code}`)}>
                                        <FontAwesomeIcon
                                            icon={faClone}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* copied modal */}
            <Modal
                show={copiedModal}
                centered
                className="swap-modal promo-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-3 py-5">
                        <h2 className="font-16 lh-16 m-0 fw-normal text-center">Link Copied!</h2>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default PromoView