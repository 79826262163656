/**
    * @description      : 
    * @author           : Saif
    * @group            : 
    * @created          : 08/11/2023 - 00:58:28
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 08/11/2023
    * - Author          : Saif
    * - Modification    : 
**/
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import axios from "axios";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomLoader from "../../Components/CustomLoader";
import { checkAudioFormat } from "../../Util/helpers";

import { placeholderImage, userImage } from "../../Assets/images";

import "./style.css";
import BASEURL from "../../Config/global";
import moment from "moment";

const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();




  const [formData, setFormData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [featuredState, setFeaturedState] = useState(false);
  const [article, setArticle] = useState(null);
  const [articleError, setArticleError] = useState(false);

  const [articleImage, setArticleImage] = useState(null);
  const [articleThumbnail, setArticleThumbnail] = useState(null);

  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/user/profile/${id}`);
        console.log(response.data)
        setFormData(response.data.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  // console.log(formData)






  let last_active_login = " ";
  let date_joined = " ";

  if (moment(formData?.last_active_login, moment.ISO_8601, true).isValid()) {
    last_active_login = moment(formData?.last_active_login).format('MM-DD-YYYY') + " " + moment(formData?.last_active_login).format('HH:mm:ss');
  }
  if (moment(formData?.date_joined, moment.ISO_8601, true).isValid()) {
    date_joined = moment(formData?.date_joined).format('MM-DD-YYYY') + " " +
      moment(formData?.date_joined).format('HH:mm:ss');
  }

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-3">
              <h2 className="mainTitle">
                <BackButton />
                User Details
              </h2>
            </div>
          </div>
          {/* <div className="row ">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="userImageProfile text-left mb-5">
                    <img src={formData?.user_image?.includes('assets/no_image') ? userImage : formData?.user_image} />
                  </div>
                </div>


              </div>
            </div>
          </div>  */}
          <div className="row mb3">
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">First Name:</p>
              <p className="text-capitalize">{formData?.first_name}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Last Name:</p>
              <p className="text-capitalize">{formData?.last_name}</p>
            </div>

            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Email:</p>
              <p className="text-capitalize">{formData?.email}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Phone:</p>
              <p className="text-capitalize">{formData?.phone_number && formData?.phone_number !== '' ? formData?.phone_number : '-'}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">City:</p>
              <p className="text-capitalize">{formData?.city && formData?.city !== '' ? formData?.city : '-'}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">State:</p>
              <p className="text-capitalize">{formData?.state && formData?.state !== '' ? formData?.state : '-'}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Zip:</p>
              <p className="text-capitalize">{formData?.zip && formData?.zip !== '' ? formData?.zip : '-'}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Country:</p>
              <p className="text-capitalize">{formData?.country && formData?.country !== '' ? formData?.country : '-'}</p>
            </div>
            

            <div className=" col-lg-3 mb-3">
              <p className="text-bold">User Type:</p>
              <p className="text-capitalize">{formData?.status === "sub_user" ? "Household" : formData?.status}</p>
            </div>

            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Subscription Type:</p>
              <p className="text-capitalize">{formData?.subscription_type && formData?.subscription_type != null ? formData?.subscription_type : "-"}</p>
            </div>


            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Birthdate:</p>
              <p className="text-capitalize">{formData?.date_of_birth && formData?.date_of_birth !== '' ? formData?.date_of_birth : '-'}</p>
            </div>


            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Created:</p>
              <p className="text-capitalize">{date_joined ? date_joined : "-"}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Last Active:</p>
              <p className="text-capitalize">{last_active_login ===" " ? "-" : last_active_login}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Offer Code:</p>
              <p className="text-capitalize">{formData?.propagation_code ? formData?.propagation_code : "-"}</p>
            </div>
          </div>
          <br/>
          <br/>
          <br/>

          <h2 className="mainTitle mb-4">{"Financial Profile"}</h2>
          <div className="row">
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Legal First Name:</p>
              <p className="text-capitalize">{formData?.legal_first_name ? formData?.legal_first_name : "-"}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Legal Last Name:</p>
              <p className="text-capitalize">{formData?.legal_last_name ? formData?.legal_last_name : "-"}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Birthdate:</p>
              <p className="text-capitalize">{formData?.legal_date_of_birth ? formData?.legal_date_of_birth : "-"}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Phone No:</p>
              <p className="text-capitalize">{formData?.legal_phone_number ? formData?.legal_phone_number : "-"}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Email:</p>
              <p className="text-capitalize">{formData?.legal_email ? formData?.legal_email : "-"}</p>
            </div>
            <div className=" col-lg-3 mb-3">
              <p className="text-bold">Address:</p>
              <p className="text-capitalize">{formData?.legal_address ? formData?.legal_address : "-"}</p>
            </div>
            
          </div>

            {/* {
              dataPropagation?.is_propagation_allowed ?

                <div className="row mb-3">
                  <div className="col-12 mx-1">
                      <h2 className="mainTitle mb-4">{dataPropagation?.title}</h2>
                      <p className="mb-1"><span className='lightColor'>Propagation Description: </span>{dataPropagation?.description}</p>
                      <p className="mb-0"><span className='lightColor'>Propagation Promo Description: </span>{dataPropagation?.code_description}</p>
                  </div>

                  <div className="col-12">
                    <hr className="my-4" />
                  </div>

                  <div className="col-12">
                    <div className="row g-0 px-4">
                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Code</small>
                        <p>{dataPropagation?.code}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Price</small>
                        <p>{dataPropagation?.price}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Expires</small>
                        <p>{(dataPropagation?.is_expiry_unlimited) ? 'Unlimited' : moment(dataPropagation?.expiry_date).format('MM-DD-YYYY')}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Created On</small>
                        <p>{moment(dataPropagation?.created).format('MM-DD-YYYY')}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Updated at</small>
                        <p>{moment(dataPropagation?.updated).format('MM-DD-YYYY')}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12"></div>

                  <div className="col-12">
                    <div className="row g-0 px-4">

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Subscription Type</small>
                        <p>{dataPropagation?.subscription_type}ly</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">{(dataPropagation?.duration_type === 'trial_period') ? 'Trial Period' : 'Term in days'}</small>
                        <p>{dataPropagation?.duration_type === 'trial_period' ? dataPropagation?.trial_period : dataPropagation?.term_in_days}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Primary Info Slider</small>
                        <p>{dataPropagation?.propagation_primary_promo_user_name}</p>
                      </div>
                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Household Info Slider</small>
                        <p>{dataPropagation?.propagation_household_promo_user_name}</p>
                      </div>

                      <div className="col-lg mb-2"></div>

                    </div>
                  </div>

                </div>

                :
                <></>
            } */}
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default UserDetails;