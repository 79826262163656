import { useState } from "react";
import { useParams, useNavigate } from "react-router";

import axios from "axios";

import Swal from "sweetalert2";
import { questionModal, successModal } from "../../Components/CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomButton from "../../Components/CustomButton";

import "./style.css";
import BASEURL, { FE_BASE_URL } from "../../Config/global";
import { useEffect } from "react";
import { placeholderImage } from "../../Assets/images";
import CustomInput from "../../Components/CustomInput";
import CustomLoader from "../../Components/CustomLoader";

import htmlToDraft from 'html-to-draftjs';
import { convertToRaw } from "draft-js";
import { useRef } from "react";
import moment from "moment";

const InfoboxDetail = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const primaryInformation = useRef(null)
  const secondaryInformation = useRef(null)



  const [inputValue, setinputValue] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [soundImage, setSoundImage] = useState(null);
  const [target, setTarget] = useState(false);

  const [formData, setFormData] = useState();
  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${BASEURL}/api/user/infoboxes/${id}`
        );
        setFormData(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };



  if (primaryInformation?.current != null) {
    primaryInformation.current.innerHTML = ''
    const div = document.createElement("div");
    primaryInformation?.current.appendChild(div);
    console.log(primaryInformation.current)
    div?.insertAdjacentHTML("beforebegin", formData?.html_info_text)
  }

  if (secondaryInformation?.current != null) {
    secondaryInformation.current.innerHTML = ''
    const div = document.createElement("div");
    secondaryInformation?.current.appendChild(div);
    console.log(secondaryInformation.current)
    div?.insertAdjacentHTML("beforebegin", formData?.html_info_text)
  }

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Info Box Detail
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4 mb-2">
              <p className="text-bold">Slider Name:</p>
              <p>{formData?.slider_name}</p>
            </div>
            <div className="col-lg-4 mb-2">
              <p className="text-bold">Create Date:</p>
              <p>{moment(formData?.created).format('MM/DD/YYYY')}</p>
            </div>
            <div className="col-lg-4 mb-2">
              <p className="text-bold">Update Date:</p>
              <p>{moment(formData?.updated).format('MM/DD/YYYY')}</p>
            </div>

          </div>
          <div className="row">
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Company Title</p>
              <p>{formData?.company_title}</p>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Header Information Image</p>
              <div className="imageInput couponImageInput">
                {formData?.header_logo_info ? <img className="img" src={formData?.header_logo_info} /> : "---"}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Mobile Image</p>
              <div className="imageInput couponImageInput">
                {formData?.mobile_logo ? <img className="img" src={formData?.mobile_logo} /> : "---"}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Desktop Image</p>
              <div className="imageInput couponImageInput">
                {formData?.desktop_logo ? <img className="img" src={formData?.desktop_logo} /> : "---"}
              </div>
            </div>
            <div className="col-lg-4 mx-auto mb-2">
              <p className="text-bold lightColor">Information Tray</p>
              <div ref={primaryInformation}></div>
            </div>
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default InfoboxDetail;
