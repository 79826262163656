import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomLoader from "../../Components/CustomLoader";
import "./style.css";
import BASEURL from "../../Config/global";
import moment from "moment";

const SubscriptionDetails = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/user/packages/${id}`);
        setFormData(response.data.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);



  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Subscription Detail
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-lg-3 mb-2">
                  <p className="text-bold">Title:</p>
                  <p>{formData?.title}</p>
                </div>
                <div className="col-lg-3 mb-2">
                  <p className="text-bold">Price:</p>
                  <p>{formData?.price}</p>
                </div>
                <div className="col-lg-3 mb-2">
                  <p className="text-bold">Subscription Type:</p>
                  <p>{formData?.interval}ly</p>
                </div>
                <div className="col-lg-3 mb-2">
                  <p className="text-bold">Modified Date</p>
                  <p>{moment(formData.updated).format('MM/DD/YYYY')}</p>

                </div>
                <div className="col-lg-12 mb-2">
                  <p className="text-bold">Description:</p>
                  <p>{formData?.description}</p>
                </div>

                <div className="col-lg-4 mb-2">
                  <p className="text-bold">Primary User Slider</p>
                  <p>{formData?.primary_package_user?.name}</p>
                </div>

                <div className="col-lg-4 mb-2">
                  <p className="text-bold">Household User Slider</p>
                  <p>{formData?.household_package_user?.name}</p>
                </div>

              </div>
            </div>
          </div>

        </div>
      </DashboardLayout>
    </>
  );
};
export default SubscriptionDetails;
