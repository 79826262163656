/**
    * @description      : 
    * @author           : Saif
    * @group            : 
    * @created          : 25/10/2023 - 03:12:33
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/10/2023
    * - Author          : Saif
    * - Modification    : 
**/
import { useState } from "react";
import { useParams, useNavigate } from "react-router";

import axios from "axios";

import Swal from "sweetalert2";
import { questionModal, successModal } from "../../Components/CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomButton from "../../Components/CustomButton";

import "./style.css";
import BASEURL from "../../Config/global";
import { useEffect } from "react";
import { placeholderImage } from "../../Assets/images";

const EditSpecials = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [inputValue, setinputValue] = useState("");
  const [thumbnail, setSoundThumbnail] = useState('');
  const [soundImage, setSoundImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [target, setTarget] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${BASEURL}/api/user/specials/${id}`
        );
        setTitle(response.data.data[0]?.title)
        setinputValue(response.data.data[0].link);
        setSoundThumbnail(response.data.data[0].banner)
        setTarget(response.data.data[0].is_new)
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    console.log(thumbnail)
  }, []);

  const handleChange = (event) => {
    setinputValue(event.target.value);
  };

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    if (file === null || file === "undefined") {
      setSoundImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSoundImage(reader.result);
        console.log(reader)
      };
    }
  };


  const postData = async () => {
    console.log(selectedImage)
    const formDataToSend = new FormData();
    formDataToSend.append("title", title);
    formDataToSend.append("link", inputValue);
    if (selectedImage && selectedImage != null) {
      formDataToSend.append("banner", selectedImage);
    }
    formDataToSend.append("is_new", target);
    try {
      const response = await axios.patch(
        `${BASEURL}/api/user/specials/${id}`,
        formDataToSend
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title && inputValue && thumbnail) {
      questionModal
        .fire({
          title: "Do you want to update this special",
          confirmButtonText: "Update",
        })
        .then((result) => {
          if (result.isConfirmed) {
            postData();
            successModal.fire({
              text: "Special Updated Successfully",
              confirmButtonText: "Continue",
            });
            navigate("/special-management");
          }
        });
    }
  };

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Edit Specials
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-lg-4 mb-2">
                  <div className="inputWrapper">
                    <label className="mainLabel">Title*</label>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="text"
                        placeholder="Enter Title"
                        required=""
                        name="Title"
                        className="mainInput"
                        value={title || ""}
                        onChange={handleTitle}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-2">
                  <div className="inputWrapper">
                    <label className="mainLabel">Category</label>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="url"
                        placeholder="Enter Link"
                        required=""
                        name="link"
                        className="mainInput"
                        value={inputValue || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-2">
                  <p className="mainLabel mb-4">Target</p>
                  <input
                    type="checkbox"
                    name="is_new"
                    id="is_new"
                    checked={target}
                    onChange={() => {
                      setTarget(!target);
                    }}
                  />
                  <label htmlFor="is_new" className="secondaryText ms-2">
                    Open with new tab.
                  </label>
                </div>
                <div className="col-lg-4 mb-2">
                  <p className="mainLabel">Image*</p>
                  <label>
                    <div className="imageInput">
                      {soundImage ? (
                        <img src={`${soundImage}`} alt="Main" />
                      ) : (
                        thumbnail == '/media/assets/no_image.png' ? <img src={`${placeholderImage}`} alt="Main" /> :
                          <img src={`${thumbnail}`} alt="Main" />
                      )}
                    </div>
                    <input
                      type="file"
                      id="image"
                      accept="image/*"
                      className="d-none"
                      required
                      name="banner"
                      onChange={handleImageChange}
                    />
                  </label>
                </div>

              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <CustomButton
                    type="submit"
                    variant="primaryButton"
                    text="Update"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default EditSpecials;
