import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBorderAll, faEllipsisV, faListCheck, faMusic } from "@fortawesome/free-solid-svg-icons";
import {
  faClipboard,
  faCreditCard,
  faMessage,
} from "@fortawesome/free-regular-svg-icons";

import "./style.css";
import { Article, Coupon, Relaxation, Sound, Special, Story, Subscription, User } from "../../Assets/svg";
import { useContext, useState } from "react";
import { PermissionsContext } from "../PermissionContext";
import { disabledButtonByPermission } from "../../Components/Permissions";
import { Dropdown } from "react-bootstrap";

export const Sidebar = (props) => {
  const [activeKey, setActiveKey] = useState('')
  const permissionList = window.localStorage.getItem('permissionList') ? JSON.parse(window.localStorage.getItem('permissionList')) : null
  const filterPermissionsByRoute = (pathname) => {
    if (!permissionList) {
      return {}
    }
    const soundManagement = permissionList.find(f => f.module_name === pathname)
    return soundManagement;
  };
  const userSideBarUserPermission = (pathname) => {
    const findCondition = filterPermissionsByRoute(pathname)
    if (!findCondition) {
      return 'd-none'
    }
    return disabledButtonByPermission(findCondition, "can_access")
  }
  const location = useLocation();
  const viewCondition = "can_access"
  const shouldShow = () => {
    const path = location.pathname.split("/")[1]
    return path.includes("category-management")
  }
  return (
    <div className={`sidebar ${props.sideClass}`} id="sidebar">
      <ul className="list-unstyled">
        <li className="sidebar-li">
          <Link
            className={`sideLink ${location.pathname.includes("/dashboard") ? "active" : ""
              } ${userSideBarUserPermission("Dashboard")}`}
            to="/dashboard"
          >
            <span className="sideIcon">
              <FontAwesomeIcon icon={faBorderAll} />
            </span>
            <span className="sideLinkText">Dashboard</span>
          </Link>
        </li>
        <li className="sidebar-li">
          <Dropdown className="tableDropdown show dropdown">
            <Dropdown.Toggle
              variant="transparent"
              className={`sideLink text-start  text-light w-100 classicToggle ${shouldShow() ? "active" : ""}`}>
              <span className="sideIcon">
                <FontAwesomeIcon icon={faListCheck} />
              </span>
              <span >Categories</span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              align="end"
              className="tableDropdownMenu "
            >
              <Link
                className={`sideLink bide-bar-menu  ${location.pathname.includes("/category-management") ? "active" : ""}  ${userSideBarUserPermission("Category Management")}`}
                to="/category-management"
              >
                <span className="sideLinkText">Category </span>
              </Link>
              <Link
                className={`sideLink bide-bar-menu ${location.pathname.includes("/sub-category-management") ? "active" : ""}  ${location.pathname.includes("/sub-category-management") ? "active" : ""
                  }`}
                to="/sub-category-management"
              >
                <span className="sideLinkText">Sub Category </span>
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </li>
       
       
        <li className="sidebar-li">
          <Link
            className={`sideLink ${location.pathname.includes("/user") ? "active" : ""
              } ${userSideBarUserPermission("User Management")}`}
            to="/user-management"
          >
            <span className="sideIcon">
              {/* <img src={User} height={25} width={25} /> */}
              <FontAwesomeIcon icon={faListCheck} />
            </span>
            <span className="sideLinkText">Users </span>
          </Link>
        </li>
        <li className="sidebar-li">
          <Dropdown className="tableDropdown show dropdown">
            <Dropdown.Toggle
              variant="transparent"
              className={`sideLink text-start text-light  w-100 classicToggle `}>
              <span className="sideIcon">
                <FontAwesomeIcon icon={faListCheck} />
              </span>
              <span >Media </span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              align="end"
              className="tableDropdownMenu "
            >
              <Link
                className={`sideLink bide-bar-menu ${location.pathname.includes("/sound") ? "active" : ""
                  } ${userSideBarUserPermission("Sound Management")}`}
                to="/sound-management"
              >
                <span className="sideIcon">
                  {/* <img src={Sound} height={25} width={25} /> */}
                  <FontAwesomeIcon icon={faMusic} />
                </span>
                <span className="sideLinkText">Sound</span>
              </Link>
              <Link
                className={`sideLink bide-bar-menu ${location.pathname.includes("/relaxation") ? "active" : ""
                  } ${userSideBarUserPermission("Relaxation Management")}`}
                to="/relaxation-management"
              >
                <span className="sideIcon">
                  {/* <img src={Relaxation} height={25} width={25} /> */}
                  <FontAwesomeIcon icon={faMusic} />
                </span>
                <span className="sideLinkText">Relaxation </span>
              </Link>
              <Link
                className={`sideLink bide-bar-menu ${location.pathname.includes("/story") ? "active" : ""
                  } ${userSideBarUserPermission("Story Management")}`}
                to="/story-management"
              >
                <span className="sideIcon">
                  {/* <img src={Story} height={25} width={25} /> */}
                  <FontAwesomeIcon icon={faMusic} />
                </span>
                <span className="sideLinkText">Story </span>
              </Link>
              <Link
                className={`sideLink bide-bar-menu ${location.pathname.includes("/article") ? "active" : ""
                  } ${userSideBarUserPermission("Article Management")}`}
                to="/article-management"
              >
                <span className="sideIcon">
                  {/* <img src={Article} height={25} width={25} /> */}
                  <FontAwesomeIcon icon={faMusic} />
                </span>
                <span className="sideLinkText">Article </span>
              </Link>
            </Dropdown.Menu>
          </Dropdown>

        </li>

        <li className="sidebar-li">
          <Link
            className={`sideLink ${location.pathname.includes("/subscription") ? "active" : ""
              } ${userSideBarUserPermission("Subscription Management")}`}
            to="/subscription-management"
          >
            <span className="sideIcon">
              {/* <img src={Subscription} height={25} width={25} /> */}
              <FontAwesomeIcon icon={faListCheck} />
            </span>
            <span className="sideLinkText">Subscriptions </span>
          </Link>
        </li>

        <li className="sidebar-li">
          <Link
            className={`sideLink ${location.pathname.includes("/special") ? "active" : ""
              } ${userSideBarUserPermission("Special Management")}`}
            to="/special-management"
          >
            <span className="sideIcon">
              {/* <img src={Special} height={25} width={25} /> */}
              <FontAwesomeIcon icon={faMusic} />
            </span>
            <span className="sideLinkText">Specials </span>
          </Link>
        </li>

        <li className="sidebar-li">
          <Link
            className={`sideLink ${location.pathname.includes("/coupon") ? "active" : ""
              }`}
            to="/promo-management"
          >
            <span className="sideIcon">
              {/* <img src={Coupon} height={25} width={25} /> */}
              <FontAwesomeIcon icon={faListCheck} />
            </span>
            <span className="sideLinkText">Promos </span>
          </Link>
        </li>

        <li className="sidebar-li">
          <Dropdown className="tableDropdown show dropdown">
            <Dropdown.Toggle
              variant="transparent"
              className={`sideLink text-start text-light  w-100 classicToggle `}>
              <span className="sideIcon">
                <FontAwesomeIcon icon={faListCheck} />
              </span>
              <span >Permissions </span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              align="end"
              className="tableDropdownMenu "
            >
              <Link
                className={`sideLink bide-bar-menu ${location.pathname.includes("/role-management") ? "active" : ""
                  }  
              ${userSideBarUserPermission("Roles Management")}
              `}
                to="/roles-management"
              >
                <span className="sideIcon">
                  {/* <img src={Coupon} height={25} width={25} /> */}
                  <FontAwesomeIcon icon={faListCheck} />
                </span>
                <span className="sideLinkText">Roles </span>
              </Link>
              <Link
                className={`sideLink bide-bar-menu ${location.pathname.includes("/employee-management") ? "active" : ""
                  } 
              ${userSideBarUserPermission("Employee Management")}

               `}
                to="/employee-management"
              >
                <span className="sideIcon">
                  {/* <img src={Coupon} height={25} width={25} /> */}
                  <FontAwesomeIcon icon={faListCheck} />
                </span>
                <span className="sideLinkText">Employees  </span>
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        <li className="sidebar-li">
          <Link
            className={`sideLink ${location.pathname.includes("/infobox-management") ? "active" : ""
              } 

               `}
            to="/infobox-management"
          >
            <span className="sideIcon">
              {/* <img src={Coupon} height={25} width={25} /> */}
              <FontAwesomeIcon icon={faListCheck} />
            </span>
            <span className="sideLinkText">Info Box  </span>
          </Link>
        </li>
        <li className="sidebar-li">
          <Link
            className={`sideLink ${location.pathname.includes("/upload-management") ? "active" : ""
              } 

               `}
            to="/upload-management"
          >
            <span className="sideIcon">
              {/* <img src={Coupon} height={25} width={25} /> */}
              <FontAwesomeIcon icon={faListCheck} />
            </span>
            <span className="sideLinkText">Uploads  </span>
          </Link>
        </li>
        <li className="sidebar-li">
          <Link
            className={`sideLink ${location.pathname.includes("/asset-management") ? "active" : ""
              } 

               `}
            to="/asset-management"
          >
            <span className="sideIcon">
              {/* <img src={Coupon} height={25} width={25} /> */}
              <FontAwesomeIcon icon={faListCheck} />
            </span>
            <span className="sideLinkText">Assets  </span>
          </Link>
        </li>
        <li className="sidebar-li">
          <Link
            className={`sideLink ${location.pathname.includes("/platform-management") ? "active" : ""
              } 

               `}
            to="/platform-management"
          >
            <span className="sideIcon">
              {/* <img src={Coupon} height={25} width={25} /> */}
              <FontAwesomeIcon icon={faListCheck} />
            </span>
            <span className="sideLinkText">Platforms  </span>
          </Link>
        </li>


        {/* <li className="sidebar-li">
          <Link
            className={`sideLink ${
              location.pathname.includes("/media") ? "active" : ""
            }`}
            to="/media"
          >
            <span className="sideIcon">
              <FontAwesomeIcon icon={faMusic} />
            </span>
            <span className="sideLinkText">Media</span>
          </Link>
        </li> */}

      </ul>
    </div>
  );
};
