import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import axios from "axios";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";
import CustomLoader from "../../Components/CustomLoader";
import { successModal } from "../../Components/CustomModal";
import "./style.css";
import BASEURL from "../../Config/global";
import moment from "moment";

const EditSubscription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [sliderList, setSliderList] = useState([]);


  useEffect(() => {
    getSlierByApi();
  }, []);

  const getSlierByApi = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/infoboxes`
      );
      setSliderList(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/user/packages/${id}`);
        setFormData(response.data.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);



  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoader(true);
      const formDataMethod = new FormData();
      formDataMethod.append("title", formData.title);
      formDataMethod.append("price", formData.price);
      formDataMethod.append("interval", formData.interval);
      formDataMethod.append("description", formData.description);
      formDataMethod.append("primary_package_user", (formData?.primary_package_user?.id || formData?.primary_package_user?.id !== NaN) ? formData?.primary_package_user?.id : null);
      formDataMethod.append("household_package_user", formData.household_package_user?.id || formData?.household_package_user?.id !== NaN ? formData.household_package_user?.id : null);
      const response = await axios.patch(`${BASEURL}/api/user/packages/${id}`, formDataMethod);
      if (!response.data.error) {
        successModal.fire({
          text: "Subscription Edited Successfully",
          confirmButtonText: "Continue",
        });
        setLoader(false);
        navigate("/subscription-management");
      } else {
        setLoader(false);
      }
      // Handle the response data here, e.g., log it or use it in your application.
      console.log("Response data:", response.data);
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);

      // You can also provide specific error messages or actions based on the error type:
      if (error.response) {
        // The request was made, but the server responded with an error.
        console.error("Server error:", error.response.data);
      } else if (error.request) {
        // The request was made, but there was no response from the server.
        console.error("No response received from the server.");
      } else {
        // Something else went wrong.
        console.error("An unexpected error occurred:", error.message);
      }
    }
  }

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Edit Subscription
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">

                  <div className="col-lg-3 mb-2">
                    <CustomInput
                      label="Title"
                      labelClass="mainLabel"
                      required
                      type="text"
                      name="title"
                      value={formData.title || ""}
                      placeholder="Enter Title"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-3 mb-2">
                    <CustomInput
                      label="Price"
                      labelClass="mainLabel"
                      required
                      type="number"
                      name="price"
                      value={formData.price || ""}
                      placeholder="Enter Price"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>



                  <div className="col-lg-3 mb-2">
                    <CustomInput
                      label="Modified Date"
                      labelClass="mainLabel"
                      type="text"
                      name="updated"
                      value={moment(formData.updated).format('MM/DD/YYYY')}
                      inputClass="mainInput"
                      onChange={handleChange}
                      disabled={true}
                    />
                  </div>


                  <div className="col-lg-3 mb-2">
                    <p className="mainLabel mb-4">Subscription Type*</p>
                    <label className="secondaryText me-3">
                      <input
                        type="radio"
                        name="interval"
                        required
                        value={"month"}
                        className="me-1"
                        checked={formData?.interval == "month" ? true : false}
                        onChange={handleChange}
                      />
                      Monthly
                    </label>
                    <label className="secondaryText">
                      <input
                        type="radio"
                        name="interval"
                        required
                        value={"year"}
                        className="me-1"
                        checked={formData?.interval == "year" ? true : false}
                        onChange={handleChange}
                      />
                      Yearly
                    </label>
                  </div>

                  <div className="col-lg-12 mb-2">
                    <div className="inputWrapper">
                      <label className="mainLabel">Description*</label>
                      <textarea
                        rows="5"
                        placeholder="Enter Description"
                        required=""
                        name="description"
                        className="mainInput"
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea></div>
                  </div>

                  <div className="col-lg-4 mb-2">
                    <p className="mainLabel">Primary User</p>
                    {sliderList && (
                      <select
                        name="primary_package_user"
                        id="primary_package_user"
                        className="mainInput w-100"
                        value={formData?.primary_package_user?.id || ""}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFormData({
                            ...formData, [name]: {
                              ...formData[name],
                              "id": Number(value)
                            }
                          });
                        }}
                      >
                        <option value={null}> Select </option>
                        {sliderList.length > 0 && sliderList.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.slider_name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="col-lg-4 mb-2">
                    <p className="mainLabel">Household User</p>
                    {sliderList && (
                      <select
                        name="household_package_user"
                        id="household_package_user"
                        className="mainInput w-100"
                        value={formData?.household_package_user?.id || ""}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFormData({
                            ...formData, [name]: {
                              ...formData[name],
                              "id": Number(value)
                            }
                          });
                        }}
                      >
                        <option value={null}> Select </option>
                        {sliderList.length > 0 && sliderList.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.slider_name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
                {/* {loginError.error == true && (
                  <div className="row mb-3">
                    <div className="col-12">
                      <p className="secondaryText">{loginError.text}</p>
                    </div>
                  </div>
                )} */}
                <div className="row">
                  <div className="col-12">
                    <CustomButton
                      type="submit"
                      variant="primaryButton"
                      text="Update"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default EditSubscription;
