import { DashboardLayout } from "../../Layout/DashboardLayout";
import BASEURL from "../../Config/global";
import PromoForm from "../../Components/Displays/Forms/PromoForm";
import { useParams } from "react-router";
import { useEffect, useState } from "react";

const EditCoupon = () => {

  const { id } = useParams();
  const [formConfig, setformConfig] = useState({
    "main_config": {
      "id": null,
      "title": "Edit Promo",
      "form_type": "Edit",
    },
    "initial_data": {},
    "api_config": {
      "url": `${BASEURL}/api/user/coupon`,
      "method": "POST",
    },
    "slider_api_config": {
      "url": `${BASEURL}/api/user/infoboxes`,
      "method": "GET",
    },
    "button_config": {
      "title": "Update Promo",
    }
  })

  useEffect(() => {
    if (id && id !== null && id !== undefined) {
      setformConfig({
        ...formConfig,
        "main_config": {
          "id": id,
          "title": "Update Promo",
          "form_type": "Edit",
        }
      })
    }
  }, [id]);

  return (
    <>
      <DashboardLayout>
        <PromoForm config={formConfig} />
      </DashboardLayout>
    </>
  );
};
export default EditCoupon;



// "initial_data": {
//   "title": "Promo",
//   "description": "Enjoy meditations, stories, articles, sounds and music to relax and have a good night's rest. Share with your Household members.",
//   "code_description": "PedIm team free coupon subscriptiondsadsa",
//   "code": "QWERTY01912",
//   "price": "1000",
//   "expiry_date": "2024-01-02",
//   "is_expiry_unlimited": true,
//   "subscription_type": "Yearly",
//   "duration_type": "term_in_days",
//   "trial_period": "",
//   "term_in_days": "10",
//   "uses_allowed": "50",
//   "current_uses": "50",
//   "active_status": false,
//   "primary_slider_id": "9",
//   "household_slider_id": "5",
//   "is_propagation_allowed": true,
//   "propagation_title": "Promo Propagation",
//   "propagation_description": "Enjoy meditations, stories, articles, sounds and music to relax and have a good night's rest. Share with your Household members.",
//   "propagation_code_description": "PedIm team free coupon subscriptiondsadsa",
//   "propagation_code": "QWERTY01912132453354",
//   "propagation_price": "312",
//   "propagation_expiry_date": "2024-01-25",
//   "propagation_is_expiry_unlimited": true,
//   "propagation_subscription_type": "Monthly",
//   "propagation_duration_type": "propagation_term_in_days",
//   "propagation_trial_period": "",
//   "propagation_term_in_days": "1202",
//   "propagation_primary_slider_id": "9",
//   "propagation_household_slider_id": "7",
//   "primary_promo_user": "9",
//   "household_promo_user": "9",
//   "propagation_uses_allowed": "123023",
//   "created": "2024-08-15T14:40:35.442180-04:00",
//   "updated": "2024-08-15T14:40:36.312113-04:00",
// },