import { useState, useEffect } from "react";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import "./style.css";
import BASEURL, { FE_BASE_URL } from "../../Config/global";
import PromoForm from "../../Components/Displays/Forms/PromoForm";

const AddCoupon = () => {

  const [formConfig, setformConfig] = useState({
    "main_config": {
      "id": null,
      "title": "Add Promo",
      "form_type": "Add",
    },
    "initial_data": {
      "title": "",
      "description": "",
      "code_description": "",
      "code": "",
      "price": null,
      "expiry_date": "",
      "is_expiry_unlimited": false,
      "subscription_type": "month",
      "duration_type": "trial_period",
      "trial_period": null,
      "term_in_days": null,
      "number_of_uses_allowed": null,
      "mtag": "",
      "primary_slider_id": null,
      "household_slider_id": null,
      "is_propagation_allowed": false,
      "propagation_title": "",
      "propagation_description": "",
      "propagation_code_description": "",
      "propagation_code": "",
      "propagation_price": null,
      "propagation_expiry_date": null,
      "propagation_is_expiry_unlimited": true,
      "propagation_subscription_type": "year",
      "propagation_duration_type": "term_in_days",
      "propagation_trial_period": null,
      "propagation_term_in_days": null,
      "propagation_primary_slider_id": null,
      "propagation_household_slider_id": null,
      "propagation_is_propagation_allowed":false,
      "promo_asset": null,
      "promo_propagation_asset": null,
      "countries": ''
    },
    "api_config": {
      "url": `${BASEURL}/api/user/coupon`,
      "method": "POST",
    },
    'slider_api_config': {
      "url": `${BASEURL}/api/user/infoboxes`,
      "method": "GET",
    },
    "button_config": {
      "title": "Add Promo",
    }
  })

  return (
    <>
      <DashboardLayout>
        <PromoForm config={formConfig} />
      </DashboardLayout >
    </>
  );
};
export default AddCoupon;
