import { useEffect, useState } from "react";
import "./style.css";
import CustomInput from "../../Components/CustomInput";
import { AddImageComponent } from "../CategoryManagement/ImageComponent";

const BaseSubComponent = ({ formData, setFormData, platforms }) => {

    const [soundImage, setSoundImage] = useState(null);
    const [iconImage, setIconImage] = useState(null);
    const [platformId, setPlatFormId] = useState(formData?.platform?.id);

    console.log("formData", formData)
    useEffect(() => {
        if (formData?.file_upload) {
            setSoundImage(formData?.file_upload);
        }
        if (formData?.icon) {
            setIconImage(formData?.icon);
        }
    }, [formData])
    useEffect(() => {
        setFormData({ ...formData, platform_id: formData?.platform?.id })
    }, [])
    console.log("formData", formData)
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });

    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file === null || file === "undefined") {
            setSoundImage(null);
            setFormData({ ...formData, file_upload: null })
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setFormData({ ...formData, file_upload: reader.result })
                setSoundImage(reader.result);
            };
        }
    };
    const handleRemoveImage = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setFormData({ ...formData, file_upload: null })
        setSoundImage(null)
    }
    const handleChangePlatform = (event)=>{
        setFormData({ ...formData, platform_id: event.target.value, })
        setPlatFormId(event.target.value)
    }
    return (
        <>
            <div className="row mx-1">
                <div className="col-3">
                    <p className="mainLabel">Select Category*</p>
                    {platforms && (
                        <select
                            name="platform_id"
                            id="platform_id"
                            className="mainInput w-100"
                            required
                            value={platformId || ""}
                            onChange={handleChangePlatform}
                        >
                            <option value={'undefined'}>Select</option>
                            {platforms.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.title}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
                <div className="col-3">
                    <CustomInput
                        label="Size"
                        labelClass="mainLabel"
                        required
                        type="text"
                        name="size"
                        value={formData?.size || ""}
                        placeholder="Enter Size"
                        inputClass="mainInput"
                        onChange={handleChange}
                    />

                </div>
                <div className="col-6">
                    <CustomInput
                        label="Title"
                        labelClass="mainLabel"
                        required
                        type="text"
                        name="title"
                        value={formData?.title || ""}
                        placeholder="Enter Title"
                        inputClass="mainInput"
                        onChange={handleChange}
                    />
                </div>
                <div className="col-4 ">
                    <AddImageComponent
                        title={`Image`}
                        inputClass=" w-100"
                        soundImage={soundImage}
                        handleImageChange={(event) => handleImageChange(event)}
                        handleRemoveImage={(event) => handleRemoveImage(event)}
                    />
                </div>
                <div className="col-8">
                    <div className="row">
                        <div className="col-12">
                            <CustomInput
                                label="Description"
                                labelClass="mainLabel"
                                required
                                type="text"
                                name="description"
                                value={formData?.description || ""}
                                placeholder="Enter Description"
                                inputClass="mainInput"
                                onChange={handleChange}
                            />

                        </div>
                        <div className="col-12">
                            <CustomInput
                                label="Instructions"
                                labelClass="mainLabel"
                                required
                                type="text"
                                name="instruction"
                                value={formData?.instruction || ""}
                                placeholder="Enter Instructions"
                                inputClass="mainInput"
                                onChange={handleChange}
                            />

                        </div>
                    </div>
                </div>

                {/* <div className="col-6">
                    <AddImageComponent
                        title={`Icon`}
                        inputClass="asset-upload col-12"
                        soundImage={iconImage}
                        handleImageChange={handleImageChangeIcon}
                        handleRemoveImage={handleRemoveImageIcon}
                    />
                </div> */}


            </div>



        </>
    );
};
export default BaseSubComponent;
