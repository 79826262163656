/**
    * @description      : 
    * @author           : Saif
    * @group            : 
    * @created          : 28/10/2023 - 01:29:34
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 28/10/2023
    * - Author          : Saif
    * - Modification    : 
**/
import { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { Dropdown, Modal, ModalBody, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";

import CustomTable from "./../../Components/CustomTable";
import CustomButton from "../../Components/CustomButton";
import CustomFilters from "../../Components/CustomFilters";
import CustomPagination from "../../Components/CustomPagination";
import { questionModal, successModal } from "../../Components/CustomModal";

import "./style.css";
import BASEURL from "../../Config/global";
import { placeholderImage } from "../../Assets/images";
import { fetchData } from "../../Services/Sound";
import { PermissionsContext } from "../../Layout/PermissionContext";
import { disabledButtonByPermission } from "../../Components/Permissions";

const CategoryManagement = () => {
  const soundInputRef = useRef()
  const [soundCategoryData, setSoundCategoryData] = useState([]);
  const [meditationCategoryData, setMeditationCategoryData] = useState([]);
  const [storyCategoryData, setStoryCategoryData] = useState([]);
  const [articleCategoryData, setArticleCategoryData] = useState([]);

  const [soundCount, setSoundcount] = useState(null);
  const [soundEnteries, setSoundEnteries] = useState(10);
  const [soundOffset, setSoundOffset] = useState(0);
  const [soundDateFrom, setSoundDateFrom] = useState("");
  const [soundDateTo, setSoundDateTo] = useState("");
  const [soundFilterSearchValue, setSoundFilterSearchValue] = useState("");

  const [meditationCount, setMeditationcount] = useState(null);
  const [meditationEnteries, setMeditationEnteries] = useState(10);
  const [meditationOffset, setMeditationOffset] = useState(0);
  const [meditationDateFrom, setMeditationDateFrom] = useState("");
  const [meditationDateTo, setMeditationDateTo] = useState("");
  const [meditationFilterSearchValue, setMeditationFilterSearchValue] =
    useState("");

  const [storyCount, setStorycount] = useState(null);
  const [storyEnteries, setStoryEnteries] = useState(10);
  const [storyOffset, setStoryOffset] = useState(0);
  const [storyDateFrom, setStoryDateFrom] = useState("");
  const [storyDateTo, setStoryDateTo] = useState("");
  const [storyFilterSearchValue, setStoryFilterSearchValue] = useState("");

  const [articleCount, setArticlecount] = useState(null);
  const [articleEnteries, setArticleEnteries] = useState(20);
  const [articleOffset, setArticleOffset] = useState(0);
  const [articleDateFrom, setArticleDateFrom] = useState("");
  const [articleDateTo, setArticleDateTo] = useState("");
  const [articleFilterSearchValue, setArticleFilterSearchValue] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [soundImage, setSoundImage] = useState('');
  const [sound, setSound] = useState('');
  const [meditationImage, setMeditationImage] = useState('');
  const [storiesImage, setStoriesImage] = useState('');
  const [articalsImage, setArticalsImage] = useState('');
  const [uploadModal, setUploadModal] = useState(false);
  const [showSwapModal, setShowSwapModal] = useState(false);
  const [swapId, setSwapId] = useState(null);
  const [swapInputError, setInputError] = useState({ error: true, text: '' });
  const [swapType, setSwapType] = useState(null);
  const [swapRecordId, setSwapRecordId] = useState(null);
  
  const { permissions } = useContext(PermissionsContext)
  
  useEffect(() => {
    document.title = "Relax Scofa | Category Management";
  }, []);   

  useEffect(() => {
    fetchFeaturedBanner();
  }, [])

  useEffect(() => {
    fetchSound();
  }, [soundEnteries, soundFilterSearchValue, soundDateTo]);

  useEffect(() => {
    fetchMeditation();
  }, [meditationEnteries, meditationFilterSearchValue, meditationDateTo]);

  useEffect(() => {
    fetchStory();
  }, [storyEnteries, storyFilterSearchValue, storyDateTo]);

  useEffect(() => {
    fetchArticle();
  }, [articleEnteries, articleFilterSearchValue, articleDateTo]);
  const modulesWisePermission = (condition) => {
    if (!permissions) {
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }
  // Fetch Apis
  const fetchFeaturedBanner = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/sounds/featuredcategories`
      );
      let length = response?.data?.data.length - 1;
      if (response?.data?.data[length]?.sounds_banner) {
        setSoundImage(response?.data?.data[length]?.sounds_banner)
      } else {
        setSoundImage(null)
      }
      setMeditationImage(response?.data?.data[length]?.meditation_banner)
      setStoriesImage(response?.data?.data[length]?.stories_banner)
      setArticalsImage(response?.data?.data[length]?.articles_banner)
    } catch (error) {
      console.error(error);
    }
  }
  const fetchSound = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/sounds/soundscategories?limit=${soundEnteries}&offset=${soundOffset}&start_date=${soundDateFrom}&end_date=${soundDateTo}&search=${soundFilterSearchValue}`
      );
      if(response.data.data){
        const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence })
        setSoundCategoryData(sortedData);
      }
      setSoundcount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  }
  const fetchMeditation = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/meditation/meditationcategories?limit=${meditationEnteries}&offset=${meditationOffset}&start_date=${meditationDateFrom}&end_date=${meditationDateTo}&search=${meditationFilterSearchValue}`
      );
      if(response.data.data){
        const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence })
        setMeditationCategoryData(sortedData);
      }
      setMeditationcount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  }
  const fetchStory = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/stories/storiescategories?limit=${storyEnteries}&offset=${storyOffset}&start_date=${storyDateFrom}&end_date=${storyDateTo}&search=${storyFilterSearchValue}`
      );
      if(response.data.data){
        const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence })
        setStoryCategoryData(sortedData);
      }
      setStorycount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  }
  const fetchArticle = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/articles/articlecategories?limit=${articleEnteries}&offset=${articleOffset}&start_date=${articleDateFrom}&end_date=${articleDateTo}&search=${articleFilterSearchValue}`
      );
      if(response.data.data){
        const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence })
        setArticleCategoryData(sortedData);
      }
      setArticlecount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  }
  // Fetch Apis

  const soundDeleteCategory = async (id) => {
    try {
      const response = await axios.delete(
        `${BASEURL}/api/sounds/soundscategories/${id}`
      );
      setSoundCategoryData(soundCategoryData.filter((item) => item.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  const soundDeleteCategoryAction = (id, name) => {
    questionModal
      .fire({
        title: `Do you want to delete category ${name}?`,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          soundDeleteCategory(id);
          successModal.fire({
            text: `Category ${name} has been deleted`,
            confirmButtonText: "Continue",
          });
        }
      });
  };

  const meditationDeleteCategory = async (id) => {
    try {
      const response = await axios.delete(
        `${BASEURL}/api/meditation/meditationcategories/${id}`
      );
      if (!response?.data.error) {
        setMeditationCategoryData(
          meditationCategoryData.filter((item) => item.id !== id)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const meditationDeleteCategoryAction = (id, name) => {
    questionModal
      .fire({
        title: `Do you want to delete category ${name}?`,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          meditationDeleteCategory(id);
          successModal.fire({
            text: `Category ${name} has been deleted`,
            confirmButtonText: "Continue",
          });
        }
      });
  };

  const storyDeleteCategory = async (id) => {
    try {
      const response = await axios.delete(
        `${BASEURL}/api/stories/storiescategories/${id}`
      );
      if (!response?.data.error) {
        setStoryCategoryData(
          storyCategoryData.filter((item) => item.id !== id)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const storyDeleteCategoryAction = (id, name) => {
    questionModal
      .fire({
        title: `Do you want to delete category ${name}?`,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          storyDeleteCategory(id);
          successModal.fire({
            text: `Category ${name} has been deleted`,
            confirmButtonText: "Continue",
          });
        }
      });
  };

  const articleDeleteCategory = async (id) => {
    try {
      const response = await axios.delete(
        `${BASEURL}/api/articles/articlecategories/${id}`
      );
      if (!response?.data.error) {
        setArticleCategoryData(
          articleCategoryData.filter((item) => item.id !== id)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const articleDeleteCategoryAction = (id, name) => {
    questionModal
      .fire({
        title: `Do you want to delete category ${name}?`,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          articleDeleteCategory(id);
          successModal.fire({
            text: `Category ${name} has been deleted`,
            confirmButtonText: "Continue",
          });
        }
      });
  };

  const [soundBannerUploadedImage, setSoundBannerUploadedImage] = useState('')
  const [soundBannerUploadedShowImage, setSoundBannerUploadedShowImage] = useState('')
  const [meditationBannerUploadedImage, setMeditationBannerUploadedImage] = useState('')
  const [meditationBannerUploadedShowImage, setMeditationBannerUploadedShowImage] = useState('')
  const [storiesBannerUploadedImage, setStoriesBannerUploadedImage] = useState('')
  const [storiesBannerUploadedShowImage, setStoriesBannerUploadedShowImage] = useState('')
  const [articalsBannerUploadedImage, setArticalsBannerUploadedImage] = useState('')
  const [articalsBannerUploadedShowImage, setArticalsBannerUploadedShowImage] = useState('')

  const handleSoundChange = (event) => {
    const file = event.target.files[0];
    setSoundBannerUploadedImage(file);

    if (file === null || file === "undefined") {
      setSoundImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSoundImage(reader.result);

      };
    }
    console.log(soundBannerUploadedImage)
  };

  const handleRemoveSoundImage = ()=>{
    setSoundBannerUploadedImage(null);
    setSoundImage(null);
  }

  const handleMeditationChange = (event) => {
    const file = event.target.files[0];
    setMeditationBannerUploadedImage(file);

    if (file === null || file === "undefined") {
      setMeditationImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setMeditationImage(reader.result);
        console.log(reader)
      };
    }
  };

  const handleRemoveMeditationImage = ()=>{
    setMeditationBannerUploadedImage(null);
    setMeditationImage(null);
  }
  
  const handleStoriesChange = (event) => {
    const file = event.target.files[0];
    setStoriesBannerUploadedImage(file);

    if (file === null || file === "undefined") {
      setStoriesImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setStoriesImage(reader.result);
        console.log(reader)
      };
    }
  };
  const handleRemoveStoriesImage = ()=>{
    setStoriesBannerUploadedImage(null);
    setStoriesImage(null);
  }
  const handleArticalsChange = (event) => {
    const file = event.target.files[0];
    setArticalsBannerUploadedImage(file);

    if (file === null || file === "undefined") {
      setArticalsImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setArticalsImage(reader.result);
        console.log(reader)
      };
    }
  };

  const handleRemoveArticalsImage = ()=>{
    setArticalsBannerUploadedImage(null);
    setArticalsImage(null);
  }

  const updatedBanner = (item) => {

    const formDataToSend = new FormData();
    if (typeof soundBannerUploadedImage === 'object') {
      formDataToSend.append("sounds_banner", soundBannerUploadedImage);
    }

    if (typeof meditationBannerUploadedImage === 'object') {
      formDataToSend.append("meditation_banner", meditationBannerUploadedImage);
    }

    if (typeof storiesBannerUploadedImage === 'object') {
      formDataToSend.append("stories_banner", storiesBannerUploadedImage);
    }

    if (typeof articalsBannerUploadedImage === 'object') {
      formDataToSend.append("articles_banner", articalsBannerUploadedImage);
    }


    async function bannerPostData() {
      try {
        const response = await axios.patch(`${BASEURL}/api/sounds/featuredcategories`, formDataToSend);
        if (!response.data.error) {
          fetchFeaturedBanner();
          setUploadModal(false);
          setSoundBannerUploadedShowImage('')
          setArticalsBannerUploadedShowImage('')
          setStoriesBannerUploadedShowImage('')
          setMeditationBannerUploadedShowImage('')
        }
      } catch (error) {
        console.error(error);
        setUploadModal(false);
        setSoundBannerUploadedShowImage('')
        setArticalsBannerUploadedShowImage('')
        setStoriesBannerUploadedShowImage('')
        setMeditationBannerUploadedShowImage('')
      }
    }
    bannerPostData();
  }

  const editBanner = (item) => {
    setUploadModal(true);
    setSoundBannerUploadedShowImage('')
    setArticalsBannerUploadedShowImage('')
    setStoriesBannerUploadedShowImage('')
    setMeditationBannerUploadedShowImage('')
  }
  const swipModal = (id, recordId, type) => {
    setInputError({ error: false, text: '' })
    setSwapId(id);
    setSwapType(type)
    // setSwapRecordId(recordId);
    setShowSwapModal(true);
  }

  const setSorting = async () => {
    setInputError({ error: false, text: '' });
    if (swapRecordId === '') {
      setInputError({ error: true, text: 'Please Enter No' });
      return;
    }    
    if (soundInputRef.current.value > soundCategoryData.length && soundInputRef.current.value < 0) {
      setInputError({ error: true, text: 'Please Enter Correct No' });
      return;
    }
    switch (swapType) {
      case 'sound':
        swapSoundHandler()
        break;
      case 'relaxation':
        swapRelaxationHandler();
        break;
      case 'story':
        swapStoryHandler();
        break;
      case 'article':
        swapArticleHandler()
        break;
      default:
        // Default case if swapType doesn't match any defined cases
        console.log("Default case executed");
    }

  }
  const swapSoundHandler = async() => {
    let recordId = parseInt(soundInputRef.current.value, 10);
    const formDataToSend = new FormData();
    if (!soundCategoryData[recordId - 1]) {
      setInputError({ error: true, text: 'Please Enter Correct No' });
      return;
    }
    formDataToSend.append("sound_category_or_subcategory_id_1", soundCategoryData[swapId - 1].id);
    formDataToSend.append("sound_category_or_subcategory_id_2", soundCategoryData[recordId - 1].id);
    try {
      await axios.put(`${BASEURL}/api/sounds/swap-sounds-category-or-subcategory/`, formDataToSend).then(async () => {
        await fetchSound()
        setShowSwapModal(false);
      });
      // setLoader(true);
      

    } catch (error) {
      console.log(error);
    }
  }
  
  const swapRelaxationHandler = async() => {
    let recordId = parseInt(soundInputRef.current.value, 10);
    const formDataToSend = new FormData();
    if (!meditationCategoryData[recordId - 1]) {
      setInputError({ error: true, text: 'Please Enter Correct No' });
      return;
    }
    formDataToSend.append("meditation_category_or_subcategory_id_1", meditationCategoryData[swapId - 1].id);
    formDataToSend.append("meditation_category_or_subcategory_id_2", meditationCategoryData[recordId - 1].id);
    try {
      await axios.put(`${BASEURL}/api/meditation/swap-meditations-category-or-subcategory/`, formDataToSend)
        .then(async () => {
          await fetchMeditation()
          setShowSwapModal(false);
        });

      // setSoundCategoryData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }
  const swapStoryHandler = async() => {
    let recordId = parseInt(soundInputRef.current.value, 10);
    if (!storyCategoryData[recordId - 1]) {
      setInputError({ error: true, text: 'Please Enter Correct No' });
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append("stories_category_or_subcategory_id_1", storyCategoryData[swapId - 1].id);
    formDataToSend.append("stories_category_or_subcategory_id_2", storyCategoryData[recordId - 1].id);
    try {
      await axios.put(`${BASEURL}/api/stories/swap-stories-category-or-subcategory/`, formDataToSend)
      .then(async () => {
        await fetchStory()
        setShowSwapModal(false);
      });

    } catch (error) {
      console.log(error);
    }
  }

  const swapArticleHandler = async() => {
    let recordId = parseInt(soundInputRef.current.value, 10);
    const formDataToSend = new FormData();
    if (!articleCategoryData[recordId - 1]) {
      setInputError({ error: true, text: 'Please Enter Correct No' });
      return;
    }
    formDataToSend.append("articles_category_or_subcategory_id_1", articleCategoryData[swapId - 1].id);
    formDataToSend.append("articles_category_or_subcategory_id_2", articleCategoryData[recordId - 1].id);
    try {
      await axios.put(`${BASEURL}/api/articles/swap-articles-category-or-subcategory/`, formDataToSend)
      .then(async () => {
        await fetchArticle()
        setShowSwapModal(false);
      });
      // setSoundCategoryData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }




  const soundCategoryTitle = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "categories",
      title: "Categories",
    },
    {
      key: "subcategory",
      title: "No of Sub categories",
    },
    {
      key: "noOfTracks",
      title: "No of tracks",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  const meditationCategoryTitle = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "category",
      title: "Category",
    },
    {
      key: "subcategory",
      title: "No of Sub categories",
    },
    {
      key: "noOfTracks",
      title: "No of tracks",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  const storyCategoryTitle = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "category",
      title: "Category",
    },
    {
      key: "subcategory",
      title: "No of Sub categories",
    },
    {
      key: "noOfTracks",
      title: "No of tracks",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  const articleCategoryTitle = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "categry",
      title: "Category",
    },
    {
      key: "subcategory",
      title: "No of Sub categories",
    },
    {
      key: "noOfTracks",
      title: "No of tracks",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">Category</h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <Tabs
                defaultActiveKey="sounds"
                id="uncontrolled-tab-example"
                className="customTabs mb-4"
              >
                <Tab eventKey="sounds" title="Sounds">
                  <div className="row mb-3">
                    <div className="col-12 text-end">
                      <Link
                        to={"add-sound-category"}
                        className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
                      >
                        Add Sound Category
                      </Link>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <CustomFilters
                        enteries={soundCategoryData.length}
                        totalCount={soundCount}
                        entriesFilter={true}
                        setEnteries={setSoundEnteries}
                        filterSearch={true}
                        filterSearchValue={soundFilterSearchValue}
                        setFilterSearchValue={setSoundFilterSearchValue}
                        // dateFilter={true}
                        setDateFrom={setSoundDateFrom}
                        setDateTo={setSoundDateTo}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <CustomTable headers={soundCategoryTitle}>
                        <tbody>
                          {soundCategoryData &&
                            soundCategoryData.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <button type="button" className="btn lightColor outline-0 border-0" onClick={() => swipModal(index + 1, item.id, "sound")}>{index + 1}</button>
                                </td>
                                <td className="text-capitalize">{item.name}</td>
                                <td className="text-capitalize">{item.sub_category_count}</td>
                                <td className="text-capitalize">{item.sound_count}</td>

                                <td>
                                  <Dropdown className="tableDropdown">
                                    <Dropdown.Toggle
                                      variant="transparent"
                                      className="notButton classicToggle"
                                    >
                                      <FontAwesomeIcon icon={faEllipsisV} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      align="end"
                                      className="tableDropdownMenu"
                                    >
                                      <Link
                                        className={`tableAction ${modulesWisePermission('can_edit')}`}
                                        to={`/category-management/edit-sound-category/${item.id} `}
                                      >
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className="tableActionIcon"
                                        />
                                        Edit
                                      </Link>

                                      <button
                                        type="button"
                                        className={`tableAction ${modulesWisePermission('can_delete')}`}
                                        onClick={() => {
                                          soundDeleteCategoryAction(
                                            item.id,
                                            item.name
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          className="tableActionIcon"
                                        />
                                        Delete
                                      </button>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </CustomTable>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <CustomPagination
                        enteries={soundCategoryData.length}
                        totalCount={soundCount}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="relaxation" title="Relaxation">
                  <div className="row mb-3">
                    <div className="col-12 text-end">
                      <Link
                        to={"add-relaxation-category"}
                        className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
                      >
                        Add Relaxation Category
                      </Link>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <CustomFilters
                        enteries={meditationCategoryData.length}
                        totalCount={meditationCount}
                        entriesFilter={true}
                        setEnteries={setMeditationEnteries}
                        filterSearch={true}
                        filterSearchValue={meditationFilterSearchValue}
                        setFilterSearchValue={setMeditationFilterSearchValue}
                        // dateFilter={true}
                        setDateFrom={setMeditationDateFrom}
                        setDateTo={setMeditationDateTo}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <CustomTable headers={meditationCategoryTitle}>
                        <tbody>
                          {meditationCategoryData &&
                            meditationCategoryData.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <button type="button" className="btn lightColor outline-0 border-0" onClick={() => swipModal(index + 1, item.id, "relaxation")}>{index + 1}</button>
                                </td>
                                <td className="text-capitalize">{item.name}</td>
                                <td className="text-capitalize">{item.sub_category_count}</td>
                                <td className="text-capitalize">{item.sound_count}</td>

                                <td>
                                  <Dropdown className="tableDropdown">
                                    <Dropdown.Toggle
                                      variant="transparent"
                                      className="notButton classicToggle"
                                    >
                                      <FontAwesomeIcon icon={faEllipsisV} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      align="end"
                                      className="tableDropdownMenu"
                                    >
                                      <Link
                                        className={`tableAction ${modulesWisePermission('can_edit')}`}
                                        to={`/category-management/edit-relaxation-category/${item.id}`}
                                      >
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className="tableActionIcon"
                                        />
                                        Edit
                                      </Link>
                                      <button
                                        type="button"
                                        className={`tableAction ${modulesWisePermission('can_delete')}`}
                                        onClick={() => {
                                          meditationDeleteCategoryAction(
                                            item.id,
                                            item.name
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          className="tableActionIcon"
                                        />
                                        Delete
                                      </button>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </CustomTable>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      {" "}
                      <CustomPagination
                        enteries={meditationCategoryData.length}
                        totalCount={meditationCount}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="stories" title="Stories">
                  <div className="row mb-3">
                    <div className="col-12 text-end">
                      <Link
                        to={"add-story-category"}
                        className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
                      >
                        Add Story Category
                      </Link>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <CustomFilters
                        enteries={storyCategoryData.length}
                        totalCount={storyCount}
                        entriesFilter={true}
                        setEnteries={setStoryEnteries}
                        filterSearch={true}
                        filterSearchValue={storyFilterSearchValue}
                        setFilterSearchValue={setStoryFilterSearchValue}
                        // dateFilter={true}
                        setDateFrom={setStoryDateFrom}
                        setDateTo={setStoryDateTo}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <CustomTable headers={storyCategoryTitle}>
                        <tbody>
                          {storyCategoryData &&
                            storyCategoryData.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <button type="button" className="btn lightColor outline-0 border-0" onClick={() => swipModal(index + 1, item.id, "story")}>{index + 1}</button>
                                </td>                               
                                <td className="text-capitalize">{item.name}</td>
                                <td className="text-capitalize">{item.sub_category_count}</td>
                                <td className="text-capitalize">{item.sound_count}</td>

                                <td>
                                  <Dropdown className="tableDropdown">
                                    <Dropdown.Toggle
                                      variant="transparent"
                                      className="notButton classicToggle"
                                    >
                                      <FontAwesomeIcon icon={faEllipsisV} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      align="end"
                                      className="tableDropdownMenu"
                                    >
                                      <Link
                                className={`tableAction ${modulesWisePermission('can_edit')}`}
                                to={`/category-management/edit-stories-category/${item.id}`}
                                      >
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className="tableActionIcon"
                                        />
                                        Edit
                                      </Link>
                                      <button
                                        type="button"
                                        className={`tableAction ${modulesWisePermission('can_delete')}`}
                                        onClick={() => {
                                          storyDeleteCategoryAction(
                                            item.id,
                                            item.name
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          className="tableActionIcon"
                                        />
                                        Delete
                                      </button>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </CustomTable>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      {" "}
                      <CustomPagination
                        enteries={storyCategoryData.length}
                        totalCount={storyCount}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="articles" title="Articles">
                  <div className="row mb-3">
                    <div className="col-12 text-end">
                      <Link
                        to={"add-article-category"}
                        className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
                      >
                        Add Article Category
                      </Link>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <CustomFilters
                        enteries={articleCategoryData.length}
                        totalCount={articleCount}
                        entriesFilter={true}
                        setEnteries={setArticleEnteries}
                        filterSearch={true}
                        filterSearchValue={articleFilterSearchValue}
                        setFilterSearchValue={setArticleFilterSearchValue}
                        // dateFilter={true}
                        setDateFrom={setArticleDateFrom}
                        setDateTo={setArticleDateTo}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <CustomTable headers={articleCategoryTitle}>
                        <tbody>
                          {articleCategoryData &&
                            articleCategoryData.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <button type="button" className="btn lightColor outline-0 border-0" onClick={() => swipModal(index + 1, item.id, "article")}>{index + 1}</button>
                                </td> 
                                <td className="text-capitalize">{item.name}</td>
                                <td className="text-capitalize">{item.sub_category_count}</td>
                                <td className="text-capitalize">{item.sound_count}</td>

                                <td>
                                  <Dropdown className="tableDropdown">
                                    <Dropdown.Toggle
                                      variant="transparent"
                                      className="notButton classicToggle"
                                    >
                                      <FontAwesomeIcon icon={faEllipsisV} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      align="end"
                                      className="tableDropdownMenu"
                                    >
                                      <Link
                                className={`tableAction ${modulesWisePermission('can_edit')}`}
                                to={`/category-management/edit-article-category/${item.id}`}
                                      >
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className="tableActionIcon"
                                        />
                                        Edit
                                      </Link>
                                      <button
                                        type="button"
                                        className={`tableAction ${modulesWisePermission('can_delete')}`}
                                        onClick={() => {
                                          articleDeleteCategoryAction(
                                            item.id,
                                            item.name
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          className="tableActionIcon"
                                        />
                                        Delete
                                      </button>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </CustomTable>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      {" "}
                      <CustomPagination
                        enteries={articleCategoryData.length}
                        totalCount={articleCount}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="featured" title="Featured Banner's">
                  <div className="FeaturedBanner mt-5">
                    <div className="row">
                      <div className="col-md-12 mb-5">
                        <div className="featuredBannerBox">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <h4 className="fs-4">Sounds Featured Banner</h4>
                            <button className="btn text-white" onClick={() => editBanner("Sounds Featured Banner")}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: '25px' }}
                              />
                            </button>
                          </div>
                          <div class="uploadBanner">
                            <label className="d-block">
                              <div className="imageInput">
                                {soundImage ? (
                                  <img src={`${soundImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                                ) : (
                                  <img src={`${placeholderImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                                )}
                              </div>
                              <input
                                type="file"
                                id="image"
                                accept="image/*"
                                className="d-none"
                                required
                                name="banner"
                                onChange={handleSoundChange}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-5">
                        <div className="featuredBannerBox">

                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <h4 className="fs-4">Relaxation Featured Banner</h4>
                            <button className="btn text-white" onClick={() => editBanner("Sounds Featured Banner")}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: '25px' }}
                              />
                            </button>
                          </div>

                          {/* <h4>Meditation Featured Banner</h4> */}
                          <div class="uploadBanner">
                            {/* <p className="mainLabel">Image*</p> */}
                            <label className="d-block">
                              <div className="imageInput">
                                {meditationImage ? (
                                  <img src={`${meditationImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                                ) : (
                                  <img src={`${placeholderImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                                )}
                              </div>
                              <input
                                type="file"
                                id="image"
                                accept="image/*"
                                className="d-none"
                                required
                                name="banner"
                                onChange={handleMeditationChange}
                              />
                            </label>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-12 mb-5">
                        <div className="featuredBannerBox">

                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <h4 className="fs-4">Stories Featured Banner</h4>
                            <button className="btn text-white" onClick={() => editBanner("Sounds Featured Banner")}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: '25px' }}
                              />
                            </button>
                          </div>

                          {/* <h4>Stories Featured Banner</h4> */}
                          <div class="uploadBanner">
                            {/* <p className="mainLabel">Image*</p> */}
                            <label className="d-block">
                              <div className="imageInput">
                                {storiesImage ? (
                                  <img src={`${storiesImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                                ) : (
                                  <img src={`${placeholderImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />

                                )}
                              </div>
                              <input
                                type="file"
                                id="image"
                                accept="image/*"
                                className="d-none"
                                required
                                name="banner"
                                onChange={handleStoriesChange}
                              />
                            </label>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-12 mb-5">
                        <div className="featuredBannerBox">

                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <h4 className="fs-4">Articles Featured Banner</h4>
                            <button className="btn text-white" onClick={() => editBanner("Sounds Featured Banner")}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ fontSize: '25px' }}
                              />
                            </button>
                          </div>

                          {/* <h4>Articals Featured Banner</h4> */}
                          <div class="uploadBanner">
                            {/* <p className="mainLabel">Image*</p> */}
                            <label className="d-block">
                              <div className="imageInput">
                                {articalsImage ? (
                                  <img src={`${articalsImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                                ) : (
                                  <img src={`${placeholderImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                                )}
                              </div>
                              <input
                                type="file"
                                id="image"
                                accept="image/*"
                                className="d-none"
                                required
                                name="banner"
                                onChange={handleArticalsChange}
                              />
                            </label>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>


        <Modal
          show={uploadModal}
          centered
          className="upload-modal"
          backdrop="static"
        >
          <Modal.Body>
            <div className="py-2">
              <div className="d-flex justify-content-end align-items-center">
                {/* <h2 className="fs-5 fw-normal">{updateBannerCategoryName}</h2> */}
                <button className="btn text-white" onClick={() => { setUploadModal(false); }}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ fontSize: '20px' }}
                  />
                </button>
              </div>
              <div class="uploadBanner mb-4">
                <h2 className="fs-6 fw-normal">Sounds Banner</h2>
                <div className='icon-wrapper'>
                  <div className="icon-on-top-right" onClick={handleRemoveSoundImage}>
                    <FontAwesomeIcon className="cross-icon-style" icon={faTimesCircle} />
                  </div>
                </div>
                <label className="d-block">
                  <div className="imageInput">
                    {soundImage ? (
                      <img src={`${soundImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                    ) : (
                      <img src={`${placeholderImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    required
                    name="banner"
                    onChange={handleSoundChange}
                  />
                </label>
              </div>
              <div class="uploadBanner mb-4">
                <h2 className="fs-6 fw-normal">Meditation Banner</h2>
                <div className='icon-wrapper'>
                  <div className="icon-on-top-right" onClick={handleRemoveMeditationImage}>
                    <FontAwesomeIcon className="cross-icon-style" icon={faTimesCircle} />
                  </div>
                </div>
                <label className="d-block">
                  <div className="imageInput">
                    {meditationImage ? (
                      <img src={`${meditationImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                    ) : (
                      <img src={`${placeholderImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    required
                    name="banner"
                    onChange={handleMeditationChange}
                  />
                </label>
              </div>
              <div class="uploadBanner mb-4">
                <h2 className="fs-6 fw-normal">Stories Banner</h2>
                <div className='icon-wrapper'>
                  <div className="icon-on-top-right" onClick={handleRemoveStoriesImage}>
                    <FontAwesomeIcon className="cross-icon-style" icon={faTimesCircle} />
                  </div>
                </div>
                <label className="d-block">
                  <div className="imageInput">
                    {storiesImage ? (
                      <img src={`${storiesImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                    ) : (
                      <img src={`${placeholderImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />

                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    required
                    name="banner"
                    onChange={handleStoriesChange}
                  />
                </label>
              </div>
              <div class="uploadBanner mb-4">
                <h2 className="fs-6 fw-normal">Articals Banner</h2>
                <div className='icon-wrapper'>
                  <div className="icon-on-top-right" onClick={handleRemoveArticalsImage}>
                    <FontAwesomeIcon className="cross-icon-style" icon={faTimesCircle} />
                  </div>
                </div>
                <label className="d-block">
                  <div className="imageInput">
                    {articalsImage ? (
                      <img src={`${articalsImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                    ) : (
                      <img src={`${placeholderImage}`} alt="Main" style={{ width: '100%', height: '200px' }} />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    required
                    name="banner"
                    onChange={handleArticalsChange}
                  />
                </label>
              </div>
              <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-grow-1 ms-auto' onClick={updatedBanner}>
                <span className=''>Upload</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showSwapModal}
          centered
          className="swap-modal"
          backdrop="static"
        >
          <Modal.Body>
            <div className="pb-5 w-100 d-flex flex-column align-items-center">
              <div className="text-end ms-auto">
                <button
                  className="closeButton notButton ms-auto"
                  onClick={() => { setShowSwapModal(false); }}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="cross-icon"
                    color="white"
                  />
                </button>
              </div>
              <div className="w-100 d-flex flex-column align-items-center" style={{ maxWidth: '500px' }}>
                <div className="d-flex align-items-center mt-3 pt-3">
                  <h2 className="mb-0 me-4">Category <b className="mx-2">{swapId}</b> swap with </h2>
                  <input type="number"
                    ref={soundInputRef}
                    onChange={() => setInputError({ error: false, text: '' })}
                  />
                </div>
                {swapInputError?.error && <small className="text-danger mt-3">{swapInputError.text}</small>}
              </div>
              <div className="mt-3 pt-3 d-flex gap-3 justify-content-center">
                <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={setSorting}>
                  <span className=''>Update</span>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </DashboardLayout>
    </>
  );
};
export default CategoryManagement;
