import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';

export const ExportToExcel = ({ apiData, fileName, dateTo, dateFrom }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const processDataHandler = () => {
        let processDaata = []
        for (let index = 0; index < apiData.length; index++) {
            const element = apiData[index];
            processDaata.push({
                "User Id": element.id,
                "First Name": element?.first_name,
                "Last Name": element?.last_name,
                "User Name": element?.first_name + ' ' + element?.last_name,
                "Email": element.email,
                "Date Of Birth": element.date_of_birth,
                "Zip": element.zip,
                "State": element.state,
                "Country": element.country,
                "Subscription": element?.subscription_type && element?.subscription_type != null ? element?.subscription_type : "",
                "Parent User": element?.parent && element?.parent != null ? element?.parent?.email : "",
                "Coupon Code": element?.coupon && element?.coupon != null ? element?.coupon : "",
                "User Type": element?.status === "sub_user" ? "Household" : element?.status,
                "Created": `${element?.date_joined != null && moment.utc(element?.date_joined).local().format("MM-DD-YYYY")}-${moment.utc(element?.date_joined).local().format("HH:mm:ss")}`,
                "Last Active": element?.last_active_login != null ? moment.utc(element?.last_active_login).local().format("MM-DD-YYYY"):''
            });
        }
        return processDaata;
    }
   
    const exportToCSV = (apiData, fileName) => {
        let name = `${fileName}${dateFrom?"-"+moment.utc(dateFrom).local().format("MM-DD-YYYY"):''}${dateTo?"-"+moment.utc(dateTo).local().format("MM-DD-YYYY"):''}`
        const processData = processDataHandler(apiData);
        const ws = XLSX.utils.json_to_sheet(processData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, name + fileExtension);
    };

    return (
        <FontAwesomeIcon size={'xl'}  icon={faFileExcel} onClick={() => exportToCSV(apiData, fileName)}/>
    );
};